section.section,
.relatedPosts {
  /**
     * Commmon classes for all sections.
     */
  padding-top: 30px;
  padding-bottom: 30px;

  @include media-breakpoint-down(sm) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &:first-of-type {
    padding-top: 220px;

    @include media-breakpoint-down(lg) {
      padding-top: 150px;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 130px;
    }

    .single-post &,
    .single-case-study & {
      padding-top: 0;
    }
  }

  &:nth-last-child(2) {
    padding-bottom: 0;
  }

  &:last-of-type {

    &.flexiblePromos,
    &.fullWidthImage,
    &.hero,
    &.hero.postHeader,
    &.promos {
      padding-bottom: 0;
      padding-top: 0;

      &.notLast {
        padding-bottom: 60px;

        @include media-breakpoint-down(sm) {
          padding-bottom: 50px;
        }

        @include media-breakpoint-down(xs) {
          padding-bottom: 40px;
        }
      }
    }
  }

  .halfWidth {
    width: 50%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .fullWidth {
    width: 100%;
  }

  .archiveHeader {
    h1 {
      font-size: 48px;
      text-transform: none;

      @include media-breakpoint-down(lg) {
        font-size: 44px;
      }

      @include media-breakpoint-down(md) {
        font-size: 40px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 35px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 30px;
      }
    }
  }

  .textSectionTitle {
    &:after {
      content: "";
      display: block;
      width: 65px;
      height: 2px;
      background: $orange;
      margin: 30px 0;
    }
  }

  h1.textSectionTitle {
    font-size: 48px;
    text-transform: none;
    font-weight: 900;

    @include media-breakpoint-down(lg) {
      font-size: 44px;
    }

    @include media-breakpoint-down(md) {
      font-size: 40px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 35px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 30px;
    }
  }

  h2.textSectionTitle {
    font-size: 36px;
    text-transform: none;
    font-weight: 900;

    @include media-breakpoint-down(lg) {
      font-size: 34px;
    }

    @include media-breakpoint-down(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 30px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 28px;
    }
  }

  .centerdCta {
    text-align: center;
  }

  /**
     * Hero Section.
     */
  &.hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    h1 {
      font-size: 48px;
      font-weight: 900;

      @include media-breakpoint-down(lg) {
        font-size: 44px;
      }

      @include media-breakpoint-down(md) {
        font-size: 40px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 35px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 30px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.8;
    }

    padding-top: 100px;
    margin-top: 180px;

    .transparentHeader & {
      padding-top: 195px;
      margin-top: 0;

      @include media-breakpoint-down(lg) {
        padding-top: 125px;
      }

      @include media-breakpoint-down(xs) {
        padding-top: 105px;
      }
    }

    .ctaContent {
      .transparentHeader & {
        padding-bottom: 100px;

        @include media-breakpoint-down(lg) {
          padding-bottom: 50px;
        }

        @include media-breakpoint-down(sm) {
          padding-bottom: 25px;
        }

        @include media-breakpoint-down(xs) {
          padding-top: 0;
        }
      }
    }
  }

  /**
     * Narrow Hero Section.
     */
  &.hero-narrow {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    h1 {
      font-size: 48px;
      font-weight: 900;

      @include media-breakpoint-down(lg) {
        font-size: 44px;
      }

      @include media-breakpoint-down(md) {
        //padding-top: 25px;
        font-size: 40px;
      }

      @include media-breakpoint-down(sm) {
        //padding-top: 25px;
        font-size: 35px;
      }

      @include media-breakpoint-down(xs) {
        //padding-top: 25px;
        font-size: 30px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.8;
    }

    padding-top: 100px;
    margin-top: 145px;
    height: auto;

    @include media-breakpoint-down(lg) {
      padding-top: 35px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 35px;
      padding-bottom: 65px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 35px;
      padding-bottom: 65px;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 35px;
      padding-bottom: 65px;
    }

    .transparentHeader & {
      padding-top: 220px;
      margin-top: 0;

      @include media-breakpoint-down(lg) {
        padding-top: 150px;
      }

      @include media-breakpoint-down(xs) {
        padding-top: 130px;
      }
    }

    .ctaContent {
      .transparentHeader & {
        padding-bottom: 100px;

        @include media-breakpoint-down(lg) {
          padding-bottom: 50px;
        }

        @include media-breakpoint-down(sm) {
          padding-bottom: 25px;
        }

        @include media-breakpoint-down(xs) {
          padding-top: 0;
        }
      }

      .ctaText {
        margin-bottom: -60px;
      }
    }
  }

  /**
     * Hero V2 Section.
     */
  &.hero.postHeader {
    padding-bottom: 0;

    a.promoContent {
      text-decoration: none;

      &:hover {
        .promoButton {
          transform: scale(0.99);
          box-shadow: 2px 2px 7px 0 transparentize($black, 0.6) !important;

          &:before {
            opacity: 1;
            transform: translateX(0);
          }

          &:after {
            background: transparent url(../images/orange_arrow_right.svg) no-repeat center;
            background-size: 36px 24px;
          }
        }
      }
    }

    .promoContent {
      position: absolute;
    }

    .ctaContent {
      padding-bottom: 0;
    }

    .ctaLabel {
      &:after {
        background: $orange;
      }
    }

    .learnMore {
      color: $orange;
    }

    .promoButton {
      position: absolute;
      bottom: 30px;
      right: -40px;
      transition: $defaultTransition;
      box-shadow: 4px 8px 12px 0 transparentize($black, 0.8) !important;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      padding: 13px 16px;
      outline: none;
      display: block;
      background: $orange;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: -1;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        border-radius: 50%;
        background: $white;
        opacity: 0;
        transform: translateX(-100%);
        transition: $defaultTransition;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: transparent url(../images/white_arrow_right.svg) no-repeat center;
        background-size: 36px 24px;
      }
    }
  }

  /**
     * Services List.
     */
  &.services {
    .serviceListItem {
      background: $lightGray;
      margin-bottom: 2px;
      padding-top: 30px;
      padding-bottom: 30px;
      line-height: 1.33;
      display: block;
      text-decoration: none;
      color: $darkerGray;

      &:hover {
        background: darken($lighterGreen, 5%);

        .moreLink {
          transform: scale(0.99);
          box-shadow: 2px 2px 7px 0 transparentize($black, 0.6) !important;
          background-color: darken($orange, 5%);
        }
      }

      @include media-breakpoint-down(xs) {
        text-align: center;
        margin-bottom: 60px;
        padding-bottom: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .serviceIcon {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include media-breakpoint-down(xs) {
        justify-content: center;
        margin-bottom: 30px;
      }

      img {
        max-width: 55px;
        max-height: 55px;
        width: auto;
        height: auto;
      }
    }

    .serviceContent {
      @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
      }

      h3 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          font-size: 30px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 28px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 26px;
        }
      }
    }

    .serviceLink {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include media-breakpoint-down(xs) {
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
      }

      .moreLink {
        box-shadow: 4px 8px 12px 0 transparentize($black, 0.8) !important;
        transition: $defaultTransition;
        background: $orange url(../images/white_arrow_right.svg) no-repeat center;
        background-size: 37px 30px;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        padding: 13px 16px;
        z-index: 2;
        outline: none;
        display: block;

        @include media-breakpoint-down(xs) {
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }

  /**
     * Promo grid.
     */

  &.promos,
  &.highlights {
    .promoGrid {
      display: flex;
      flex-wrap: wrap;
    }

    .promoItem {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 550px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      overflow: hidden;
      position: relative;
      text-decoration: none;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        min-height: 0;
      }

      &:hover {
        .ctaLabel {
          &:after {
            width: 300px;
          }
        }

        .promoLinks .promoButton {
          transform: scale(0.99);
          box-shadow: 2px 2px 7px 0 transparentize($black, 0.6) !important;

          &:before {
            opacity: 1;
            transform: translateX(0);
          }

          &:after {
            background: transparent url(../images/orange_arrow_right.svg) no-repeat center;
            background-size: 23px 18px;
          }
        }

        .fullBackground img {
          transform: scale(1.2);
          opacity: 0.8;
        }
      }
    }

    .fullBackground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include media-breakpoint-down(sm) {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 300px;
      }

      @include media-breakpoint-down(xs) {
        height: 250px;
      }
    }

    .promoContent {
      width: 480px;
      max-width: 100%;
      min-height: 275px;
      max-height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-height: 0;
      }
    }

    .promoText {
      padding: 30px 30px 0 30px;
      flex-grow: 1;

      @include media-breakpoint-down(sm) {
        flex-grow: 0;
        padding: 20px 20px 0 20px;
      }
    }

    .ctaLabelContainer {
      overflow: hidden;
    }

    .ctaLabel {
      position: relative;
      display: inline-block;
      font-size: 14px;
      margin-bottom: 15px;

      &:after {
        position: absolute;
        top: 8px;
        left: 100%;
        background: $white;
      }
    }

    .ctaText {
      font-size: 15px;
      margin-bottom: 0;
    }

    .ctaTitle {
      font-size: 26px;
      margin-bottom: 15px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 20px;
      }
    }

    .promoLinks {
      width: 100%;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;

      @include media-breakpoint-down(sm) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .promoButton {
        position: relative;
        top: 0;
        left: 0;
        transition: $defaultTransition;
        box-shadow: 4px 8px 12px 0 transparentize($black, 0.8) !important;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        padding: 13px 16px;
        outline: none;
        display: block;
        background: $orange;
        overflow: hidden;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: -1px;
          left: -1px;
          z-index: -1;
          height: calc(100% + 2px);
          width: calc(100% + 2px);
          border-radius: 50%;
          background: $white;
          opacity: 0;
          transform: translateX(-100%);
          transition: $defaultTransition;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: transparent url(../images/white_arrow_right.svg) no-repeat center;
          background-size: 23px 18px;
        }
      }
    }

    // Page Promo / Blog Promo / Case Study Promo
    .page-promo,
    .blog-post-promo,
    .case-study-promo,
    .blogPost,
    .caseStudy {
      background: $darkGreen;

      .promoContent {
        background: $darkGreen;
        color: $white;

        h3 {
          color: $white;
        }

        .ctaLabel {
          &:after {
            background: $orange;
          }
        }
      }

      .learnMore {
        color: $orange;
      }

      .promoButton {
        margin-right: -54px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }

      &.halfWidth {
        .promoButton {
          @include media-breakpoint-down(md) {
            margin-right: 0;
          }
        }
      }

      &.fullWidth {
        .promoContent {
          max-width: 50%;

          @include media-breakpoint-down(sm) {
            max-width: 100%;
          }
        }
      }
    }

    .blog-post-promo,
    .blogPost {
      background: $lightGray url(../images/orange_grid_80.svg) repeat center;
      background-size: 200px;

      .promoContent {
        background: $lightGray;
        color: $darkerGray;

        h3 {
          color: $darkerGray;
        }
      }
    }

    // Client Quotes / Member quotes / Cool Stats
    .client-quotes,
    .team-quotes,
    .cool-stats {
      transition: $defaultTransition;

      .promoContent {
        height: 100%;
        width: 100%;
        color: $white;

        h3 {
          color: $white;
        }
      }

      &.fullWidth {
        @include media-breakpoint-up(md) {
          text-align: center;

          .promoContent {
            justify-content: center;
            padding-bottom: 75px;
          }

          .promoText {
            flex-grow: 0;
            max-width: 1000px;
            margin: 0 auto;
            padding: 30px;
          }

          .ctaLabel:after {
            display: block;
            position: relative;
            left: 0;
            margin: 10px auto;
          }

          .quoteAuthor {
            flex-direction: column;
            height: auto;
          }

          .authorPic {
            img {
              margin-right: 0;
              margin-bottom: 15px;
              width: 100px;
              height: 100px;
            }
          }

          .promoLinks {
            justify-content: center;
            position: absolute;
            bottom: 0;

            .learnMore {
              margin-right: 20px;
            }
          }
        }
      }

      &.lightGreen {
        background: $lightGreen;

        &:hover {
          background: darken($lightGreen, 8%);
        }

        .promoButton {
          &:before {
            background: $darkGreen;
          }

          &:after {
            background: transparent url(../images/green_arrow_right.svg) no-repeat center;
            background-size: 23px 18px;
          }
        }
      }

      &.orange {
        background: $orange;

        &:hover {
          background: darken($orange, 8%);
        }

        .promoButton {
          &:before {
            background: $orange;
          }

          &:after {
            background: transparent url(../images/orange_arrow_right.svg) no-repeat center;
            background-size: 23px 18px;
          }
        }
      }

      &:hover {
        .promoLinks .promoButton {
          &:after {
            background: transparent url(../images/white_arrow_right.svg) no-repeat center;
            background-size: 23px 18px;
          }
        }
      }

      .promoLinks {
        background: transparentize($black, 0.9);
      }

      .promoButton {
        background: $white;
      }
    }

    .theQuote {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.2;

      @include media-breakpoint-down(lg) {
        font-size: 26px;
      }

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 20px;
      }
    }

    .quoteAuthor {
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px 30px 30px;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        height: auto;
      }
    }

    .authorPic {
      @include media-breakpoint-down(sm) {
        font-size: 22px;
        margin-bottom: 10px;
      }

      img {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        margin-right: 20px;
      }
    }

    .authorInfo {
      .ctaTitle {
        font-size: 24px;
        margin-bottom: 8px;
      }

      &>div {
        max-width: 210px;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }

    // Cool-stats only
    .cool-stats {
      background: $lighterGreen;
      color: $darkerGray;

      .promoText {
        padding: 30px;
      }

      .promoLinks {
        background: $lighterGreen2;
        color: $darkerGray;
        transition: $defaultTransition;
      }

      .ctaLabel:after {
        background: $orange;
      }

      .promoContent {
        color: $darkerGray;
        background: transparentize($lighterGreen, 0.1);
        transition: $defaultTransition;
      }

      .coolStat {
        font-size: 70px;
        font-weight: 700;

        @include media-breakpoint-down(lg) {
          font-size: 60px;
        }

        @include media-breakpoint-down(md) {
          font-size: 50px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 50px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 40px;
        }
      }

      .statSource {
        font-size: 16px;
      }

      .promoButton {
        &:before {
          background: $darkGreen;
        }

        &:after {
          background: transparent url(../images/green_arrow_right.svg) no-repeat center;
          background-size: 23px 18px;
        }
      }

      &:hover {
        background: $lighterGreen2;

        .promoContent {
          background: transparentize($lighterGreen2, 0.1);
        }

        .promoLinks {
          background: $lightGreen;
        }
      }
    }
  }

  /**
     * Flexible Promo
     */
  &.flexiblePromos {
    .promoGrid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .promoItem {
      min-height: 580px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      overflow: hidden;
      position: relative;

      &.halfWidth {
        width: calc(50% - 1px);

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        min-height: 0;
        margin-top: 0;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.8;
      }

      .flexiPromoContent {
        max-width: 90%;
        padding: 30px 60px;
        z-index: 1;

        h1 {
          font-size: 48px;
          text-transform: none;

          @include media-breakpoint-down(lg) {
            font-size: 44px;
          }

          @include media-breakpoint-down(md) {
            font-size: 40px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 35px;
          }

          @include media-breakpoint-down(xs) {
            font-size: 30px;
          }
        }

        @include media-breakpoint-down(lg) {
          max-width: 65%;
        }

        @include media-breakpoint-down(md) {
          padding: 30px;
          max-width: 80%;
        }

        @include media-breakpoint-down(sm) {
          padding: 30px 15px;
          max-width: 100%;
        }
      }

      .ctaLabel {
        &:after {
          display: block;
          margin: 20px auto;
          width: 150px;
        }
      }
    }

    .ctaButton {
      margin-bottom: 0;
    }
  }

  /**
     * Text sections
     */
  &.textSection {
    .largeImage {
      margin-top: 20px;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      &.beforeText {
        margin-bottom: 60px;
      }
    }

    .textColumn {
      padding-right: 35px;
      margin-bottom: 20px;

      // h1,
      // h2,
      // h3 {
      //   font-weight: 900;
      // }

      // div[class^="custSize"] {
      //   h4 {
      //     font-size: 1.5em;
      //   }
      //}
      &.custSize--small {
        font-size: .75em;

        p {
          line-height: 1.25;
          margin-bottom: 12.5px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-right: $grid-gutter-width / 2;
      }
    }
  }

  &.textImage {

    .imageColumn,
    .textColumn {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;

      &>div,
      &>p {
        flex-grow: 1;
      }
    }

    .textColumn {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
    }

    .imageColumn {
      img {
        margin-left: 30px;
        max-width: calc(100% + 100px);

        @media only screen and (max-width: $xxl) {
          margin-left: 0;
          max-width: calc(100% + 30px);
        }

        @include media-breakpoint-down(md) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &.imageLeft {
      .textColumn {
        order: 2;

        @include media-breakpoint-down(md) {
          order: 1;
        }
      }

      .imageColumn {
        order: 1;

        @include media-breakpoint-down(md) {
          order: 2;
        }
      }

      .imageColumn {
        justify-content: flex-end;

        img {
          margin-left: 0;
          margin-right: 30px;
          max-width: calc(100% + 100px);

          @media only screen and (max-width: $xxl) {
            margin-right: 0;
            max-width: calc(100% + 30px);
          }
        }
      }
    }
  }

  /**
     * Team Members Grid
     */
  &.teamMembersGrid {
    .teamGrid {
      .teamGridItem {
        margin-bottom: 30px;
        font-size: 15px;
        transition: $defaultTransition;

        @include media-breakpoint-down(xs) {
          margin-bottom: 20px;
        }

        img {
          filter: grayscale(100%);
          margin-bottom: 20px;

          @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
          }
        }

        h5 {
          font-size: 20px;
          margin-bottom: 10px;
          font-weight: 900;

          @include media-breakpoint-down(xs) {
            margin-bottom: 5px;
            font-size: 18px;
          }

          &:after {
            content: "";
            display: block;
            width: 40px;
            height: 2px;
            background: $orange;
            margin: 15px 0 0 0;

            @include media-breakpoint-down(xs) {
              margin-top: 10px;
            }
          }
        }

        a {
          color: $normalGray;
          text-decoration: none;
          display: block;

          h5 {
            color: $darkerGray;
          }
        }

        &:hover,
        &:focus {
          img {
            filter: grayscale(0);
            opacity: 1;
          }
        }
      }
    }
  }

  /**
     * Linked Logos
     */
  &.linkedLogos {
    background: $lighterGreen;
    color: $darkerGray;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 60px;

    h1 {
      font-size: 48px;
      text-transform: none;

      @include media-breakpoint-down(lg) {
        font-size: 44px;
      }

      @include media-breakpoint-down(md) {
        font-size: 40px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 35px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 30px;
      }
    }

    .ctaLabel {
      &:after {
        display: block;
        margin: 15px auto;
        width: 150px;
      }
    }

    .logosGrid {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;
    }

    .logoItem {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      padding: 10px;

      @include media-breakpoint-down(md) {
        width: 25%;
      }

      @include media-breakpoint-down(sm) {
        width: 33.33%;
      }

      @include media-breakpoint-down(xs) {
        width: 50%;
      }

      a {
        display: block;
      }

      img {
        max-width: 160px;
        mix-blend-mode: multiply;
      }
    }
  }

  /**
     * SharpSpring Forms
     */
  &.sharpspringForm {
    &>.container {
      padding-bottom: 60px;

      .leftPos {
        order: 1;
      }

      .rightPos {
        order: 3;
      }

      .rightPost {
        order: 3;
      }

      .formContainer {
        order: 2;
      }
    }

    .smallSidebar {
      font-size: 16px;

      h5,
      h6 {
        font-size: 19px;
        font-weight: 900;
        font-style: normal;
      }
    }

    .row.leftPos {
      .formContainer {
        margin-left: 40px;
        padding-left: 60px;
        border-left: 2px solid $lightGray;
      }
    }

    .row.rightPos {
      .formContainer {
        margin-right: 40px;
        padding-right: 60px;
        border-right: 2px solid $lightGray;
      }
    }
  }

  /**
     * Testimonial slider.
     */
  &.testimonialSlider {
    .slider {
      display: flex;

      li {
        text-align: center;
        background: $lighterGreen;
        padding: 65px 30px 100px 30px;
        display: flex;
        position: relative;

        &:before {
          display: none;
        }
      }

      .ctaLabel {
        z-index: 1;
        position: relative;

        &:after {
          display: block;
          margin: 20px auto;
          width: 150px;
          background: $darkGreen;
          z-index: 2;
          transform: translate(0, 0);
          position: relative;
        }
      }

      .slideContent {
        max-width: 780px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
      }

      .theQuote {
        font-size: 28px;
        font-weight: 500;
        line-height: 1.2;

        @include media-breakpoint-down(lg) {
          font-size: 26px;
        }

        @include media-breakpoint-down(md) {
          font-size: 24px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 22px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
      }

      .quoteAuthor {
        width: 100%;
      }

      .authorPic {
        img {
          width: 120px;
          height: 120px;
          border-radius: 60px;
          margin-bottom: 20px;
        }
      }

      .authorInfo {
        margin-bottom: 0;

        .ctaTitle {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }

      .promoLinks {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $lightGreen;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        .learnMore {
          color: $white;
          margin-right: 25px;
        }

        .promoButton {
          position: relative;
          top: 0;
          left: 0;
          transition: $defaultTransition;
          box-shadow: 4px 8px 12px 0 transparentize($black, 0.8) !important;
          height: 48px;
          width: 48px;
          border-radius: 50%;
          padding: 13px 16px;
          outline: none;
          display: block;
          background: $white;
          overflow: hidden;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: -1;
            height: calc(100% + 2px);
            width: calc(100% + 2px);
            border-radius: 50%;
            background: $darkGreen;
            opacity: 0;
            transform: translateX(-100%);
            transition: $defaultTransition;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: transparent url(../images/green_arrow_right.svg) no-repeat center;
            background-size: 23px 18px;
          }
        }

        &:hover {
          background: darken($lightGreen, 5%);

          .promoButton {
            transform: scale(0.99);
            box-shadow: 2px 2px 7px 0 transparentize($black, 0.6) !important;

            &:before {
              opacity: 1;
              transform: translateX(0);
            }

            &:after {
              background: transparent url(../images/white_arrow_right.svg) no-repeat center;
              background-size: 23px 18px;
            }
          }
        }
      }
    }

    .lSAction {
      &>a {
        height: 33px;
        width: 18px;
        background: transparent url(../images/carousel_arrow.svg) no-repeat center;
        background-size: 18px 33px;
        opacity: 1;

        &:hover {
          opacity: 0.8;
        }

        &.lSNext {
          transform: rotate(180deg);
        }

        &.lSPrev {}
      }
    }
  }

  /**
     * Social Sharing Links.
     */
  &.socialSharingLinks {
    h6 {
      &:after {
        margin: 30px auto;
      }
    }
  }

  /**
     * Text + Promo Adjustments
     */

  &.textPromo {
    .textColumn {
      .ctaLabel {
        position: relative;
        display: inline-block;
        font-size: 14px;
        margin-bottom: 15px;

        &:after {
          position: absolute;
          top: 8px;
          left: 100%;
          background: $orange;
        }
      }
    }

    .promoColumn {
      a.cool-stats {
        width: inherit;

        .promoContent {
          width: inherit;

          .promoText,
          .coolStat,
          .theQuote,
          .statSource {
            width: inherit;
          }

          .coolStat {
            font-size: 3em;
            line-height: 1.4em;
            padding-bottom: 0.5em;

            @include media-breakpoint-down(lg) {
              font-size: 2.75em;
            }

            @include media-breakpoint-down(md) {
              font-size: 2.5em;
            }

            @include media-breakpoint-down(sm) {
              font-size: 2.25em;
            }

            @include media-breakpoint-down(xs) {
              font-size: 2em;
            }
          }

          .statSource {
            overflow-wrap: anywhere;
          }
        }
      }

      .promoLinks {
        width: inherit;
      }

      .promoItem {
        height: max-content;
      }

      .theQuote {
        font-size: 1rem;
      }
    }

    &.promoLeft {
      .textColumn {
        order: 2;

        @include media-breakpoint-down(md) {
          order: 1;
        }
      }

      .promoColumn {
        order: 1;

        @include media-breakpoint-down(md) {
          order: 2;
        }
      }

      .imageColumn {
        justify-content: flex-end;

        img {
          margin-left: 0;
          margin-right: 30px;
          max-width: calc(100% + 100px);

          @media only screen and (max-width: $xxl) {
            margin-right: 0;
            max-width: calc(100% + 30px);
          }
        }
      }
    }
  }
}

section.inline-quote-testemonial {
  a {
    text-decoration: none;
  }

  .client-quotes {
    transition: $defaultTransition;

    .promoContent {
      height: 100%;
      width: 100%;
      color: $black;
      display: grid;
      grid-template-columns: auto 35%;
      grid-template-rows: auto 10%;
      margin-bottom: 2rem;

      @include media-breakpoint-down(md) {
        grid-template-columns: 100%;
        grid-template-rows: auto 35% 10%;
      }
    }

    .promoText {
      width: 100%;
      display: inline-block;

      p {
        margin-bottom: 10px;
      }
    }
  }

  .theQuote {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.15;
    text-align: left;

    @include media-breakpoint-down(lg) {
      font-size: 22px;
    }

    @include media-breakpoint-down(md) {
      text-align: left;
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }

  .quoteAuthor {
    width: fit-content;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 30px 30px;
    grid-column-start: 2;
    margin-top: 1rem;
    align-self: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      grid-column-start: 1;
      grid-row-start: 2;
      justify-content: left;
      align-items: center;
      padding: 10px;
      margin-top: 0;
      display: inline;
    }

    @include media-breakpoint-down(sm) {
      height: auto;
    }

    .authorPic {
      display: table;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        margin: 0;
        margin-right: 10px;
        display: inline-block;
        vertical-align: bottom;
      }

      @include media-breakpoint-down(sm) {
        font-size: 22px;
        margin-bottom: 10px;
      }

      img {
        width: 120px;
        height: 120px;
        border-radius: 60px;
      }
    }

    .authorInfo {
      text-align: center;
      margin-bottom: 8px;

      @include media-breakpoint-down(md) {
        display: inline-block;
        text-align: left;
      }

      @include media-breakpoint-down(sm) {
        display: inline-block;
        text-align: left;
        width: 50%;
        font-size: 14px;
        margin-bottom: 2px;
        vertical-align: top;
      }

      .ctaTitle {
        font-size: 20px;
        margin-bottom: 8px;
        margin-top: 8px;
        color: $green;
      }

      &>div {
        max-width: 210px;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }
  }

  .promoLinks {
    grid-area: 2/1/2/3;

    @include media-breakpoint-down(md) {
      grid-area: 3/1;
    }

    .learnMore {
      display: block;
      text-align: center;
      color: $orange;
    }
  }
}