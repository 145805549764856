/** 
 * Services Template
 */

.serviceHeader {
    margin-top: 122px;
    background: $darkGreen;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 30px;
    position: relative;


    @include media-breakpoint-down(lg) { 
        margin-top: 108px;
    }
    @include media-breakpoint-down(sm) { 
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @include media-breakpoint-down(xs) { 
        padding-top: 40px;
        padding-bottom: 40px;
    }


    img {
        max-height: 80px;
        max-width: 80px;
        height: auto;
        width: auto;
        margin-right: 20px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        bottom: -70px;
        width: 80px;
        height: 150px;
        background: transparent url(../images/orange_grid_vert.svg) no-repeat center;
        background-size: 80px 150px; 
        z-index: 1;
        @include media-breakpoint-down(sm) { 
            display: none;
        }
    }
}
.serviceTitle {
    color: $white;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) { 
        flex-wrap: wrap;
        img {
            margin-bottom: 10px;
        }
        span {
            width: 100%;
        }
    }
}

.breadcrumb {
    background: transparent;
    padding: 0;
    border-radius: 0;
    font-size: 13px;
    color: $darkGreen;
    margin-bottom: 30px;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .breadLink {
        color: $darkGreen;

        &:after {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            color: $darkGreen;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    span {
        font-weight: 900;
    }

}

.serviceMainContainer {
    padding-bottom: 30px;
    margin-bottom: 60px;
    position: relative;

    @include media-breakpoint-down(xs) { 
        padding-bottom: 20px;
        margin-bottom: 50px;
    }

    &:after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: $grid-gutter-width / 2;
        right: $grid-gutter-width / 2;
        background: $lighterGreen;
    }

    .single-service-blocks & {
        margin-bottom: 0;
    }
}

.serviceSubtitle {
    @include media-breakpoint-down(lg) { 
        font-size: 36px;
    }
    @include media-breakpoint-down(md) { 
        font-size: 34px;
    }
    @include media-breakpoint-down(sm) { 
        font-size: 32px;
    }
    @include media-breakpoint-down(xs) { 
        font-size: 30px;
    }

    &:after {
        content: '';
        display: block;
        width: 65px;
        height: 2px;
        background: $orange;
        margin: 20px 0;
    }
}

.serviceDescription {
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) { 
        margin-bottom: 50px;
    }
    @include media-breakpoint-down(xs) { 
        margin-bottom: 40px;
    }
}

.serviceSidebar {
    border-left: 2px solid $lighterGreen;
}

main.main ul.subServicesList {
    list-style-type: none;
    padding: 0;

    li {
        padding: 0;
        margin: 0;

        &:before {
            display: none;
        }
    }

    a {
        font-size: 30px;
        color: $darkGreen;
        margin-bottom: 30px;
        text-decoration: none;

        @include media-breakpoint-down(sm) { 
            font-size: 28px;
        }
        @include media-breakpoint-down(xs) { 
            font-size: 26px;
        }

        &:hover {
            color: $orange;
            text-decoration: none;
        }
    }
}

.subService {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    @include media-breakpoint-down(xs) { 
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &:first-of-type {
        padding-top: 0;
    }

    &:after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: $grid-gutter-width / 2;
        right: $grid-gutter-width / 2;
        background: $lighterGreen;
    }
}

.subServiceTitle {
    h3 {
        @include media-breakpoint-down(lg) { 
            font-size: 28px;
        }
        @include media-breakpoint-down(md) { 
            font-size: 28px;
        }
        @include media-breakpoint-down(sm) { 
            font-size: 26px;
        }
        @include media-breakpoint-down(xs) { 
            font-size: 26px;
        }
    }
}

.promos.mainServicePromos .centerdCta {
    padding-bottom: 60px;
}
.promos.mainServicePromos,  .promos.mainServicePromos:first-of-type(), .promos.mainServicePromos:last-of-type() {
    padding-top: 60px;
}
.promos.subServicePromos, .promos.subServicePromos:first-of-type(), .promos.subServicePromos:last-of-type() {
    padding-top: 0;
}

.subServicePromos h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) { 
        font-size: 22px;
    }
    @include media-breakpoint-down(xs) { 
        font-size: 20px;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 65px;
        height: 2px;
        background: $orange;
        margin-left: 20px;
        top: -5px;
        position: relative;
    }

}

.subServiceBulletList {

    &:after {
        content: '';
        display: block;
        width: 65px;
        height: 2px;
        background: $orange;
        margin: 30px 0;
    }
}

.subServiceSidebar {
    h5 {
        font-size: 20px;
        font-weight: 900;
    }
    h6 {
        font-size: 22px;
        font-weight: 500;
        @include media-breakpoint-down(xs) { 
            font-size: 20px;
        }
    }
}
.subServiceMainContent, .subServiceSidebar {
    margin-bottom: 50px;
}




/** 
 * Team Member (Author archive)
 */

.memberMain {
    order: 2;
    padding-left: 40px;
    margin-bottom: 60px;
    
    @include media-breakpoint-down(sm) { 
        padding-left: $grid-gutter-width / 2;
    }
}

.memberHeader {
    h1 {
        font-size: 42px;
        margin-bottom: 10px;

        @include media-breakpoint-down(lg) { 
            font-size: 40px;
        }
        @include media-breakpoint-down(md) { 
            font-size: 38px;
        }
        @include media-breakpoint-down(sm) { 
            font-size: 34px;
        }
        @include media-breakpoint-down(xs) { 
            font-size: 30px;
        }
    }
    h2 {
        font-size: 32px;
        font-weight: 500;

        @include media-breakpoint-down(lg) { 
            font-size: 30px;
        }
        @include media-breakpoint-down(md) { 
            font-size: 28px;
        }
        @include media-breakpoint-down(sm) { 
            font-size: 26px;
        }
        @include media-breakpoint-down(xs) { 
            font-size: 24px;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 65px;
        height: 2px;
        background: $orange;
        margin: 30px 0;
    }
}

.memberSidebar {
    order: 1;

    


    .memberHeadshot {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-start;
        margin-bottom: 40px;

        img {
            max-width: calc(100% + 100px);

            @include media-breakpoint-down(sm) { 
                max-width: 100%;
                width: 100%;
            }
        }

        .btn {
            max-width: calc(100% + 100px);
            width: calc(100% + 100px);
            margin-left: -100px;
            padding-top: 25px;
            padding-bottom: 25px;
            
            @include media-breakpoint-down(sm) { 
                max-width: 100%;
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .socialLinks {
        display: flex;
        margin-bottom: 40px;

        a {
            cursor: pointer;
            width: 50px;
            height: 50px;
            margin-right: 5px;
            background-color: $lightGray;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: darken( $lightGray, 5% );
            }
            img {
                max-height: 16px;
                width: auto;
                max-width: 20px;
                height: auto;
            }
        }
    }

    
}

/** 
 * Error 404
 */
.error404 {
    .section.hero {
        color: $darkerGray;
        background: $lightGray;
    }
}