/**
 * Custom Fonts.
 */

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-Regular.eot");
  src: url("../fonts/VisbyCF-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-Regular.woff2") format("woff2"),
    url("../fonts/VisbyCF-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-RegularOblique.eot");
  src: url("../fonts/VisbyCF-RegularOblique.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-RegularOblique.woff2") format("woff2"),
    url("../fonts/VisbyCF-RegularOblique.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-Bold.eot");
  src: url("../fonts/VisbyCF-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-Bold.woff2") format("woff2"),
    url("../fonts/VisbyCF-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-BoldOblique.eot");
  src: url("../fonts/VisbyCF-BoldOblique.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-BoldOblique.woff2") format("woff2"),
    url("../fonts/VisbyCF-BoldOblique.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-Heavy.eot");
  src: url("../fonts/VisbyCF-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-Heavy.woff2") format("woff2"),
    url("../fonts/VisbyCF-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-HeavyOblique.eot");
  src: url("../fonts/VisbyCF-HeavyOblique.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-HeavyOblique.woff2") format("woff2"),
    url("../fonts/VisbyCF-HeavyOblique.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-Medium.eot");
  src: url("../fonts/VisbyCF-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-Medium.woff2") format("woff2"),
    url("../fonts/VisbyCF-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Visby CF";
  src: url("../fonts/VisbyCF-MediumOblique.eot");
  src: url("../fonts/VisbyCF-MediumOblique.eot?#iefix") format("embedded-opentype"),
    url("../fonts/VisbyCF-MediumOblique.woff2") format("woff2"),
    url("../fonts/VisbyCF-MediumOblique.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

/**
 * Default HTML tags.
 */

::-moz-selection {
  background: transparentize(darken($orange, 10%), 0.2);
  color: $white;
}

::selection {
  background: transparentize(darken($orange, 10%), 0.2);
  color: $white;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-backface-visibility: hidden;
}

html,
body {
  font-size: 18px;
  line-height: 1.7;
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
  transition: $defaultTransition;
}

.fullBackground {
  img {
    width: inherit;
  }
}

a {
  outline: none;
  transition: $defaultTransition;
  color: $orange;
  text-decoration: underline;

  img {
    backface-visibility: hidden;
    opacity: 1;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($orange, 5%);

    img {
      opacity: 0.8;
    }
  }
}

iframe {
  max-width: 100%;
  border: 0;
}

p,
pre,
ul,
ol,
dl,
blockquote {
  margin: 0 0 1.5rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  line-height: 1.1;
  margin: 0 0 1rem 0;
}

h1 {
  font-size: 36px;

  @include media-breakpoint-down(lg) {
    font-size: 34px;
  }

  @include media-breakpoint-down(md) {
    font-size: 32px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 28px;
  }
}

h2 {
  font-size: 32px;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    font-size: 30px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 26px;
  }
}

h3 {
  font-size: 28px;

  @include media-breakpoint-down(lg) {
    font-size: 27px;
  }

  @include media-breakpoint-down(md) {
    font-size: 26px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 25px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 24px;
  }
}

h4 {
  font-size: 22px;

  @include media-breakpoint-down(md) {
    font-size: 21px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

h5 {
  font-size: 22px;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    font-size: 21px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

h6 {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;

  @include media-breakpoint-down(md) {
    font-size: 19px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

hr {
  border: 0;
  height: 2px;
  background: $lighterGreen;
  margin: 30px 0;
}

blockquote {
  padding: 10px 30px;
  border-left: 2px solid $lighterGreen;
  color: $normalGray;
  font-size: 1.1rem;
  font-style: italic;
}

main.main {
  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding-left: 30px;
      position: relative;
      margin-bottom: 3px;

      @include media-breakpoint-down(sm) {
        padding-left: 20px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 14px;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $darkerGray;
      }
    }
  }

  ol {
    list-style-type: none;
    padding: 0;
    counter-reset: ptko-list-counter;

    li {
      counter-increment: ptko-list-counter;
      padding-left: 30px;
      position: relative;
      margin-bottom: 3px;

      @include media-breakpoint-down(sm) {
        padding-left: 20px;
      }

      &:before {
        content: counter(ptko-list-counter) ". ";
        position: absolute;
        left: 0;
      }
    }
  }
}

/**
 * Common CSS classes.
 */

main.main {
  box-shadow: 0 0 25px transparentize($black, 0.5);
  z-index: 2;
  position: relative;
  background: $white;
}

.admin-bar {
  .fixed-top {
    top: 32px;

    @include media-breakpoint-down(sm) {
      top: 46px;
    }
  }
}

// Call to actions common classes
.ctaLabel {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.1;
  margin-bottom: 18px;

  &:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 52px;
    height: 2px;
    background: $orange;
    margin-left: 20px;
    top: -5px;
    transition: $defaultTransition;
  }
}

.ctaText {
  margin-bottom: 33px;
  line-height: 1.5;
}

.ctaButton {
  margin-bottom: 50px;
}

// Various classes
.readMore {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
  padding: 12px 0;

  &:hover,
  &:focus {
    &:after {
      margin-left: 12px;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    height: 12px;
    width: 15px;
    background: transparent url(../images/read_more_arrow.svg) no-repeat center;
    background-size: 15px 12px;
    margin-left: 20px;
    transition: $defaultTransition;
  }
}

.learnMore {
  position: relative;
  top: 0;
  left: 0;

  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.backLinkContainer {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.backLink {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
  position: relative;
  padding: 15px 35px;

  &:hover,
  &:focus {
    &:before {
      left: 8px;
    }
  }

  &:before {
    content: "";
    display: inline-block;
    height: 12px;
    width: 15px;
    background: transparent url(../images/read_more_arrow.svg) no-repeat center;
    background-size: 15px 12px;
    left: 0;
    transition: $defaultTransition;
    transform: rotate(180deg);
    position: absolute;
    top: 18px;
  }
}

.fullLink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.zoomableBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: $defaultTransition;

  a:hover & {
    transform: scale(1.2);
    opacity: 0.8;
  }
}

// Default section header & title
.sectionHeader {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 150px;
    height: 80px;
    background: transparent url(../images/orange_grid.svg) no-repeat center;
    background-size: 150px 80px;
    top: 42px;
    left: -120px;
    z-index: -1;

    @include media-breakpoint-down(md) {
      top: 38px;
    }

    @include media-breakpoint-down(sm) {
      top: 34px;
    }

    @include media-breakpoint-down(xs) {
      left: -60px;
      top: 30px;
    }
  }
}

.sectionTitle {
  font-size: 48px;
  padding-bottom: 5px;
  border-bottom: 3px solid $orange;
  margin-bottom: 120px;
  font-weight: 900;

  @include media-breakpoint-down(lg) {
    font-size: 44px;
  }

  @include media-breakpoint-down(md) {
    font-size: 40px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 35px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 30px;
  }
}

.borderBottom {
  position: relative;
  padding-bottom: 60px;

  &:after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    background: $lighterGreen;
  }
}

main.main {

  .servicesList,
  .pageList,
  .linksList {
    list-style-type: none;
    padding: 0;
    //margin-bottom: 40px;

    li {
      padding: 0;

      &:before {
        display: none;
      }

      a {
        display: block;
        font-size: 20px;
        font-weight: 500;
        color: $darkerGray;
        padding: 15px 60px 15px 20px;
        line-height: 1;
        background: $lighterGreen;
        position: relative;
        margin-bottom: 5px;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
          padding: 15px 40px 15px 20px;
        }

        &:after {
          content: "";
          display: block;
          height: 11px;
          width: 14px;
          position: absolute;
          right: 35px;
          top: 50%;
          margin-top: -5px;
          transition: $defaultTransition;
          background: transparent url(../images/services_link_arrow.svg) no-repeat center;
          background-size: 14px 11px;

          @include media-breakpoint-down(lg) {
            right: 25px;
          }
        }

        &:hover {
          background: darken($lighterGreen, 5%);

          &:after {
            right: 45px;

            @include media-breakpoint-down(lg) {
              right: 35px;
            }
          }
        }
      }
    }

    .serviceList--subservice a {
      padding-left: 2rem;
    }
  }

  .linksList {
    li a {
      background: $lighterGray;

      &:hover {
        background: darken($lighterGray, 5%);
      }
    }
  }

  .pageList {
    li a {
      padding: 30px 70px 30px 30px;
      font-size: 30px;
      margin-bottom: 25px;

      @include media-breakpoint-down(lg) {
        font-size: 27px;
      }

      @include media-breakpoint-down(md) {
        font-size: 24px;
        padding: 25px 65px 25px 25px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 22px;
        padding: 20px 65px 20px 20px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 20px;
        padding: 15px 65px 15px 25px;
      }

      &:after {
        width: 22px;
        height: 18px;
        background: transparent url(../images/big_link_arrow.svg) no-repeat center;
        background-size: 23px 19px;
      }
    }
  }
}

#cookie-notice {
  box-shadow: 0 0 20px transparentize($black, 0.8);
}

.cookie-notice-revoke-container {
  text-align: left;
  pointer-events: none;

  a {
    pointer-events: all;
  }
}

.socialSharingLinks {
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(sm) {
    flex-direction: row;
  }

  a {
    margin-right: 0;
    margin-bottom: 5px;

    #copyToClipboard--img {
      max-height: 24px;
    }

    .copySuccess {
      z-index: 1000;
      all: inherit;
      position: absolute;

      img {
        max-height: 24px;
        margin-top: 15px;
      }
    }

    @keyframes copyFadeout {
      100% {
        opacity: 0;
      }

      50% {
        opacity: 0.5;
      }

      0% {
        opacity: 1;
      }
    }

    .copySuccess-visible {
      display: initial;
      animation: copyFadeout 0.5s linear 1s forwards;
    }

    .copySuccess-hidden {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
      margin-right: 5px;
    }
  }
}

.shareLinks-Row {
  flex-direction: row !important;
}

.decreseFont {
  font-size: .9em;
}

.hidden {
  display: none !important;
}

body.events {
  main.main {
    section.section.textSection.text1column {
      div.container {
        div.textColumn {
          section {
            article {
              display: inline-block;
              div {
                display: inline-block;
                + p {
                  > a.btn {
                    width: 180px;
                  }
                }
              }
            }
          }
        }
      }
    }
  } 
}