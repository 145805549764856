.btn {
    @extend .btnAnimation;
    
    border: 0;
    border-radius: 0;
    font-size: 16px;
    line-height: 1.2;
    text-shadow: none;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    padding: 16px 20px;

    &:hover {
        text-decoration: none;
    }

    .widget & {
        padding: 13px 35px;
    }
}

.btnAnimation {
    transition: $defaultTransition;
    box-shadow: 4px 8px 12px 0 transparentize($black, .8) !important;

    &:hover {
        transform: scale(.99);
        box-shadow: 2px 2px 7px 0 transparentize($black, .6) !important;
    }

    &.btn-sm {
        box-shadow: 2px 2px 8px 0 transparentize($black, .7) !important;

        &:hover {
            transform: scale(.99);
            box-shadow: 1px 1px 7px 0 transparentize($black, .5) !important;
        }
    }
}


.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
}

/**
 * Default Bootstrap buttons override
 */
.btn-primary {
    background: $orange;
    color: $white;

    &:hover, &:focus{
        background: darken($orange, 5%);
        color: $white;
    }
}
.btn-secondary {
    background: $darkGreen;
    color: $white;

    &:hover, &:focus{
        background: darken($darkGreen, 5%);
        color: $white;
    }
}
.btn-success {
    background: $lightGreen;
    color: $white;

    &:hover, &:focus{
        background: darken($lightGreen, 5%);
        color: $white;
    }
}
.btn-danger {
    background: $red;
    color: $white;

    &:hover, &:focus{
        background: darken($red, 5%);
        color: $white;
    }
}
.btn-warning {
    background: $yellow;
    color: $darkerGray;

    &:hover, &:focus{
        background: darken($yellow, 5%);
        color: $darkerGray;
    }

}
.btn-info {
    background: $lightGray;
    color: $darkerGray;

    &:hover, &:focus{
        background: darken($lightGray, 5%);
        color: $darkerGray;
    }
}
.btn-light {
    background: $lighterGray;
    color: $darkerGray;

    &:hover, &:focus{
        background: darken($lighterGray, 5%);
        color: $darkerGray;
    }
}
.btn-dark {
    background: $normalGray2;

    &:hover, &:focus{
        background: darken($normalGray2, 5%);
    }
}
.btn-link {
    box-shadow: none !important;
    background: transparent;
    color: $orange;

    &:hover, &:focus {
        color: darken($orange, 5%);
        box-shadow: none !important;
    }
}

.btn-block-readmore {
    display: block;
    margin: 0;
}

/**
 * Custom buttons
 */
.primaryNavigation .btnMenu a {
    @extend .btn, .btn-primary;
    margin-left: 15px;
    font-size: 15px;
    padding: 16px 17px;
}

.loadMoreCaseStudies {
    margin-top: 30px;
}