.gform_wrapper {
  font-size: 1em;
  margin-bottom: 1.5em;

  ul.gform_fields li.gfield {
    margin-bottom: 1rem;
  }

  .gfield_label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }

  .validation_error {
    padding-left: 0.5rem;
    font-size: 0.8rem;
  }

  .ginput_container_fileupload {
    width: fit-content;
  }

  .gform_drop_area {
    border: black thin dashed;
    border-radius: 6px;
    padding: 1em;

    .gform_drop_instructions {
      margin: 1.5em;
    }
  }

  .button {
    padding-left: 30px;
    padding-right: 30px;
  }

  textarea,
  select,
  input[type="file"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    border-radius: 3px;
  }

  input[type="select"],
  select {
    height: 2.7em;
  }

  .gform_page_footer {
    border: none !important;
  }

  .gfield_required {
    color: $white;
  }

  .gfield_error {
    background-color: $orange;
    border-radius: 3px;
  }

  input[type="submit"] {
    margin-left: 0.5rem;
  }

  li.gform_hidden {
    display: none;
  }

  ul.gform_fields {
    list-style: none;
    padding-left: 0px;
  }

  li.gfield {
    padding-left: 0.55rem;
    padding-right: 0.55rem;
    padding-bottom: 0.25rem;

    input[type="text"] {
      width: 100%;
    }

    &:last-child {
      padding-bottom: 0rem;
    }

    .gfield_checkbox {
      li {
        label {
          display: inline;
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 0.75rem;
        }
      }
    }

    input[type="checkbox"] {
      vertical-align: sub !important;
      appearance: none;
      padding: 0 0.175em;
      align-self: center;
      display: inline;
      position: relative;
      font-size: 1.25rem;
      border-radius: 0;
      height: 1em;
      width: 1em;
      border: none;
      border-bottom: solid 2px black;

      &:checked:before {
        font-size: inherit;
        color: orange
      }

      &:hover:before {
        color: lightgray;
      }

      &:checked:hover:before {
        color: black;
      }

      &:checked {
        color: $orange;
      }

      &:before {
        content: "\2714";
        color: transparent;
      }
    }

    &:before {
      content: "";
      display: none;
    }
  }

  ul.gfield_checkbox {
    li {
      padding-left: 0px;

      &:before {
        content: "";
        display: none;
      }

      label {
        padding-left: 0.25em;
      }
    }
  }

  .field_description_below {
    .gfield_description {
      padding-top: 0px !important;
    }
  }

  .gfield_description {
    font-size: 0.7rem;
    margin-left: 1rem;
  }

  .ginput_container {
    margin-left: 1rem;
    max-width: 85%;

    textarea.textarea {
      width: 100%;
      padding: 0.25rem 0.5rem;
      height: 6ch;
    }

    input[type="text"] {
      width: 100%;
    }

    .ginput_quantity_label {
      margin-left: 2rem;

      &+.ginput_quantity {
        width: 3rem;
      }
    }

    input[type="email"] {
      width: 100%;
    }

    .gfield_description,
    span input+label {
      font-size: 0.7rem;
    }
  }

  // div {
  //   margin-bottom: 30px;
  // }
}

.ptkoTextWidget_text {
  .ginput_container_checkbox {
    li {
      margin-bottom: 0.75em;

      label {
        display: inline;
      }

      br {
        display: none;
      }
    }
  }
}

// Specific to signup for newsletter footer
.signupFormFooter,
.ptko_form_widget {
  .gform_wrapper {
    width: 100%;

    .gfield_error {
      background-color: $darkGreen;
      border-radius: 3px;
    }
  }

  .ginput_container {
    margin-left: 0px;
  }

  li.gfield {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.signupFormFooter {
  padding-left: 1rem;

  .button {
    padding-top: 8px;
    padding-bottom: 11px;
    background-color: $darkGreen;

    &:hover {
      transform: scale(0.99);
      background-color: $darkGreen;
      box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.4);
    }

    &:active,
    &:focus {
      background-color: $darkGreen;
    }
  }

  .ginput_container {
    max-width: 86.6%;
  }
}

.ptko_form_widget .gform_wrapper {

  li.gfield,
  .gform_page_footer {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ginput_container {
    max-width: 100%;
  }

  .button {
    padding-left: 25px !important;
    padding-right: 25px !important;

    &:hover {
      transform: scale(0.99);
      box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.4);
    }
  }
}

.ginput_complex.ginput_container.no_prefix.has_first_name.no_middle_name.has_last_name.no_suffix.gf_name_has_2.ginput_container_name {
  & span {
    width: 48%;
    padding-right: 0px;

    &:first-child {
      margin-right: 3.5%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ginput_complex.ginput_container.no_prefix.has_first_name.no_middle_name.has_last_name.no_suffix.gf_name_has_2.ginput_container_name {
    & span {
      width: 100%;
      padding-right: 0px;
    }
  }
}

/* special for for 56 */
#gform_wrapper_56 {
  .gfield_radio {
    & .gchoice {
      display: inline;
      padding-right: 1rem;
    }
  }

  .gfield_html {
    &>h2+h2 {
      display: none;
    }
  }

  .gfield_error {
    background-color: transparent;

    .validation_message {
      border: none;
      background-color: transparent;
      padding: 10px 0px 0px 0px;
      color: red;
      font-weight: bold;
    }
  }
}