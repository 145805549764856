// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/**
 * Generic forms styles
 */

form {
  h1,
  h2,
  h3 {
    &:not(:first-child) {
      margin-top: 60px;
    }
    &:after {
      content: "";
      display: block;
      width: 65px;
      height: 2px;
      background: $orange;
      margin: 20px 0;
    }
  }

  textarea,
  select,
  input[type="file"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    border: 0;
    border-bottom: 1px solid $normalGray2;
    font-size: $form-font-size;
    padding: 2px 8px;
    line-height: $form-line-height;
    outline: none;
    background-color: $white;
    margin: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      outline: none;
      border-bottom-color: $darkerGray;
      background-color: $white;
    }

    &.error {
      border-bottom-color: $red;
    }
  }
  textarea {
    padding: 2px 0;
  }

  fieldset li label {
    font-size: 22px;
    line-height: 1.35;
    margin: 0 10px 0 0;
    padding: 2px 0;

    &.error {
      position: absolute;
      right: 0;
      font-size: 12px;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      top: 7px;
      background-color: $red;
      color: $white;
    }
  }

  button,
  .button {
    @extend .btn, .btn-primary;
    padding-left: 60px;
    padding-right: 60px;
  }

  .form_fields {
    margin-bottom: 60px;

    li.field {
      display: flex;
      margin-bottom: 20px;

      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }

      label {
        @include media-breakpoint-down(xs) {
          margin-bottom: 10px;
        }
      }

      .field_value {
        flex-grow: 1;
        min-height: 28px;

        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }

      &.textarea,
      &.upload {
        flex-wrap: wrap;

        label {
          margin-bottom: 10px;
        }

        .field_value {
          width: 100%;

          textarea {
            width: 100%;
            height: 250px;
          }
          input[type="file"] {
            width: 100%;
            font-size: 15px;
            padding-bottom: 5px;
          }
        }
      }

      &.picklist {
        .field_value {
          position: relative;

          select {
            position: absolute;
            left: 0;
            right: 0;
            max-width: 100%;
            width: 100%;
          }
        }
      }
      &.checkbox,
      &.radio {
        .field_value {
          span.checkbox-option,
          span.radio-option {
            width: 100%;
            display: flex;

            input[type="checkbox"],
            input[type="radio"] {
              margin-right: 10px;
              margin-top: 10px;
            }

            label {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

.stickyForm {
  position: -webkit-sticky;
  position: sticky;
  height: fit-content;
  background-color: $lightGray;
  top: 10rem;
  line-height: 1rem;
}

.formColumn {
  padding: 1.25rem;
  font-size: 0.8rem;

  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  input::placeholder {
    font-size: 0.9rem;
  }
}

/**
 * SharpSpring specific styles 
 */
body.sharpspring_form {
  background: transparent !important;

  /* Utils */
  .clear {
    clear: both;
  }
  .pull-right {
    float: right;
  }
  .pull-left {
    float: left;
  }
  body .hide {
    display: none;
  }
  /* reCaptcha */
  .ss-recaptcha .ss-recaptcha-logo {
    height: 24px;
    width: 24px;
    float: left;
    background: url(https://www.gstatic.com/recaptcha/api2/logo_48.png);
    background-size: 24px 24px;
    display: none;
  }
  .ss-recaptcha .ss-anchor-invisible-text {
    font-size: 12px;
    color: #7f7f7f;
  }
  .ss-recaptcha .ss-anchor-pt {
    font-size: 12px;
  }
  .ss-recaptcha .ss-anchor-pt a {
    color: #7f7f7f;
  }

  /* -------------- Errors ---------------- */
  #form_errors {
    clear: both;
    float: left;
    background: $red;
    color: $white;
    box-shadow: none;
    font-size: 12px;
    padding: 5px 6px;
    margin: 10px 0;
    border-radius: 0;
  }
  #form_errors.hide {
    display: none;
  }
}

/**
 * Form specific styles 
 */

// Contact: Footer
#form_01d5459a-dfb8-4aa8-8451-2d9c7abad5f3 {
  background: $darkGray;
  text-align: right;

  textarea,
  select,
  input[type="file"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    background: $darkGray;
    color: $white;
    font-size: 15px;
    border-bottom: 2px solid $lighterGray;
    padding: 5px 0;

    &:focus {
      border-bottom-color: $white;
    }

    &.error {
      border-bottom-color: $red;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $normalGray2;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $normalGray2;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $normalGray2;
    }
  }

  li label {
    display: none;
  }

  .form_fields {
    margin-bottom: 30px;

    li.field {
      &.textarea {
        textarea {
          height: 100px;
        }
      }
    }
  }

  button,
  .button {
    @extend .btn-dark;
    padding-left: 35px;
    padding-right: 35px;
  }
}

// Email Sign-Up: Footer
#form_5cd2d34a-1d82-4687-9888-fd4c5401ab10,
// Email Sign-Up: Embed
#form_a04d85c5-7b19-41c1-852d-bd47de234f9f {
  text-align: right;

  textarea,
  select,
  input[type="file"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    font-size: 18px;
    border-bottom: 1px solid transparent;
    padding: 13px 150px 13px 15px;

    &:focus {
      border-bottom-color: $darkerGray;
    }

    &.error {
      border-bottom-color: $red;
    }

    @media only screen and (max-width: 450px) {
      font-size: 16px;
    }
  }

  .form_fields {
    margin-bottom: 10px;
    box-shadow: 4px 8px 12px 0 transparentize($black, 0.8) !important;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -45px;
      bottom: -40px;
      width: 150px;
      height: 67px;
      background: transparent url(../images/green_grid.svg) no-repeat center;
      background-size: 150px 67px;
      z-index: -1;

      @media only screen and (max-width: 450px) {
        left: -15px;
        bottom: -30px;
        width: 88px;
        height: 39px;
        background-size: 88px 39px;
      }
    }

    li.field {
      margin-bottom: 0;
    }
  }

  fieldset {
    position: relative;
    margin: 0 15px 30px 50px;
  }

  li label {
    display: none;
  }
  button,
  .button {
    @extend .btn-secondary;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none !important;

    &:hover {
      transform: scale(1);
    }

    @media only screen and (max-width: 450px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  #submit_button {
    position: absolute;
    right: 0;
    top: 0;

    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  p {
    margin: 0;
  }

  .ss-recaptcha .ss-anchor-invisible-text,
  .ss-recaptcha .ss-anchor-pt a {
    color: transparentize($white, 0.6);
  }
}
// Email Sign-Up: Embed
#form_a04d85c5-7b19-41c1-852d-bd47de234f9f {
  .form_fields {
    &:after {
      display: none;
    }
  }
  fieldset {
    margin: 15px;
    @media only screen and (max-width: 450px) {
      margin: 15px 10px;
    }
  }
}

// Email Sign-Up: Right Rail
#form_6fa0b74d-8e50-4e68-b852-dc8bfea625e0 {
  padding: 0;

  textarea,
  select,
  input[type="file"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
    border-bottom: 1px solid transparent;
    padding: 8px 120px 8px 10px;

    &:focus {
      border-bottom-color: $darkerGray;
    }

    &.error {
      border-bottom-color: $red;
    }
  }

  .form_fields {
    margin: 3px 10px 0 3px;
    box-shadow: 3px 3px 10px 0 transparentize($black, 0.8) !important;
    position: relative;

    li.field {
      margin-bottom: 0;
    }
  }

  li label {
    display: none;
  }
  button,
  .button {
    @extend .btn-primary;
    padding: 11px 12px;
    box-shadow: none !important;

    &:hover {
      transform: scale(1);
    }
  }

  #submit_button {
    position: absolute;
    right: 10px;
    top: 3px;
    font-size: 14px;
  }

  p {
    margin: 0;
  }
}
