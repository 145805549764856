.hs_hidden {
    max-height: 0px !important;
}

div.hs_form_expander {
    width: 100%;
    overflow: hidden;
    height: fit-content;
    transition: max-height 1000ms linear;
    max-height: 1000px;

    div.hs_form_container {
        background-color: #f1f8f5;
        width: 100%;
        height: fit-content;
        padding: 1rem;

        .download_resource {
            margin-bottom: 1.5em;
        }
    }
}

section.topFooter {
    div.signupFormFooter {
        div.hbspt-form {
            margin: 1.75em 1.5em 1.5em 1.5em;
        }
    }
}

section.bottomFooter {
    div.hbspt-form {
        div.input {
            margin-bottom: 2em;

            input.hs-input[type='email'],
            input.hs-input[type='text'],
            textarea.hs-input {
                width: 100% !important;
            }

        }
    }
}

div.ptkoFormWidget_form {
    div.hbspt-form {
        margin: 0;
    }
}

.formColumn div.hbspt-form {
    margin: 3em 1.5em 1.5em 1.5em;

    form {
        .hs-form-field:not(:last-child) {
            margin-bottom: 2em;
        }

        .hs-button {
            @extend .btn, .btn-primary;
            margin-left: 0px;
        }

        .hs-form-required {
            color: red;
        }

        input[type="email"],
        input[type="text"],
        input[type="tel"] {
            font-size: inherit;
            width: 100%;
            border: none;
            height: 2.7em;
        }

        ul.hs-error-msgs {
            li {
                &:before {
                    display: none;
                }

                padding-left: 0px;
                margin-bottom: 0px;

                label.hs-error-msg {
                    font-size: inherit;
                }
            }
        }

        input[type="submit"] {
            @extend .btn, .btn-primary;
        }
    }
}

main.main {
    .formColumn {
        &>p {
            margin-bottom: 0px;

            &>br {
                display: none
            }
        }
    }

    .hs-form {
        &>div.hs-form-field {
            margin-bottom: 2.125rem;
        }

        &>div:last-of-type {
            margin-top: 2em;
        }

        label {
            font-size: 1em;
            font-weight: normal;
        }

        .hs-button {
            @extend .btn, .btn-primary;
            padding-left: 25px !important;
            padding-right: 25px !important;

            &:hover {
                transform: scale(0.99);
                box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.4);
            }
        }


        ul li {
            margin-bottom: .5rem;
        }

        .button {
            padding-left: 30px;
            padding-right: 30px;
        }

        textarea,
        select,
        input[type="file"],
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="url"] {
            width: 100%;
            border-radius: 3px;
        }

        input[type="select"],
        select {
            height: 2.7em;
        }

        ul {
            list-style: none;
            padding-left: 0px;

            &::before {
                display: none !important;
            }
        }

        li {
            padding-left: 1rem;
            padding-right: 0.55rem;
            padding-bottom: 0.25rem;

            input[type="text"] {
                width: 100%;
            }

            &:last-child {
                padding-bottom: 0rem;
            }

            input[type="checkbox"] {
                vertical-align: .1rem;
                appearance: none;
                margin-right: .25em;
                align-self: center;
                display: inline;
                position: relative;
                font-size: 1rem;
                border-radius: 0;
                height: 1em;
                width: 1em;
                border: none;
                border-bottom: solid 2px black;
                padding-left: .1rem;

                &:checked:before {
                    font-size: inherit;
                    color: orange
                }

                &:hover:before {
                    color: lightgray;
                }

                &:checked:hover:before {
                    color: black;
                }

                &:checked {
                    color: $orange;
                }

                &:before {
                    content: "\2714";
                    color: transparent;
                    vertical-align: -.175rem;
                }
            }

            &:before {
                content: "";
                display: none;
            }

            input[type="text"] {
                width: 100%;
            }

            input[type="email"] {
                width: 100%;
            }
        }
    }
}