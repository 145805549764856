/**
 * Shareable Callout [ptko_callout]
 */
.shareableCallout {
    position: relative;
    font-size: 32px;
    text-transform: none;
    font-style: normal;
    font-weight: 500;
    color: $darkerGray;
    line-height: 1.2;
    padding: 15px 30px 15px 45px;
    border: 0;

    @include media-breakpoint-down(lg) { 
        font-size: 30px;
    }
    @include media-breakpoint-down(md) { 
        font-size: 28px;
    }
    @include media-breakpoint-down(sm) { 
        font-size: 26px;
    }
    @include media-breakpoint-down(xs) { 
        font-size: 24px;
    }
    
    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        width: 25px;
        background: $orange url(../images/share.svg) no-repeat center center;
        background-size: 12px 12px;
        transition: $defaultTransition;
    }

    .calloutSharingLinks {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: transparentize( $lighterGreen2, .1 );
        position: absolute;
        top: 0;
        left: 25px;
        bottom: 0;
        right: 0;
        transition: $defaultTransition;
        padding-left: 20px;
        opacity: 0;
        pointer-events: none;

        a {
            cursor: pointer;
            width: 50px;
            height: 50px;
            margin-right: 15px;
            background-color: $lightGray;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
   
            @include media-breakpoint-down(xs) { 
                margin-right: 7px;
            }

            &:hover {
                background-color: darken( $lightGray, 5% );
            }
            img {
                max-height: 16px;
                width: auto;
                max-width: 20px;
                height: auto;
            }
        }
    }


    &:hover {

        .calloutSharingLinks {
            opacity: 1;
            pointer-events: all;
        }

        &:before {
            background-color: darken( $orange, 5% );
        }
    }
}

/**
 * Embedded Form [ptko_form]
 */
.embeddedForm {
    background: $orange;
    color: $white;
    margin: 55px 0;
    padding: 45px 65px;
    font-size: 20px;
    position: relative;
    text-align: center;
    line-height: 1.2;


    @include media-breakpoint-down(md) { 
        margin: 45px 0;
        padding: 45px;
    }
    @include media-breakpoint-down(xs) { 
        margin: 40px -10px;
        padding: 25px 30px;
    }


    &:after {
        position: absolute;
        content: '';
        height: 115px;
        width: 52px;
        background: transparent url(../images/green_grid_vert.svg) no-repeat center;
        background-size: 52px 115px;
        left: -25px;
        top: 50%;
        margin-top: -58px;
    }

    .ctaLabel {
        &:after {
            display: block;
            top: 0;
            margin: 10px auto;
            background: $white;
        }
    }
}