// Main navigation container
.navigationContainer {
    transition: $defaultTransition;
    background: $white;

    .transparentHeader.lightHeader & {
        background: -moz-linear-gradient(top, transparentize( $black, .7) 12%, transparentize( $black, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, transparentize( $black, .7) 12%, transparentize( $black, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, transparentize( $black, .7) 12%, transparentize( $black, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    }
    .transparentHeader.darkHeader & {
        background: -moz-linear-gradient(top, transparentize( $white, .7) 12%, transparentize( $white, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, transparentize( $white, .7) 12%, transparentize( $white, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, transparentize( $white, .7) 12%, transparentize( $white, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1affffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    }
    .scrolled.lightHeader &, .scrolled.darkHeader & {
        background: $white;
        box-shadow: 0 0 20px transparentize( $black, .9 );
    }

    .topNav {
        display: flex;
        align-items: center;
        padding-top: 37px;
        padding-bottom: 37px;
        position: relative;
        transition: $defaultTransition;

        .scrolled & {
            padding-top: 37px;
            padding-bottom: 37px;
        }

        @include media-breakpoint-down(lg) { 
            padding-top: 37px;
            padding-bottom: 37px;
        }
    }   
}


// Hamburger button
.hamburger {
    @extend .btnAnimation;
    position: absolute;
    right: 10px;
    top: 35px;
    background: $orange;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    padding: 13px 16px;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    outline: none;

    @include media-breakpoint-down(lg) { 
        opacity: 1;
        pointer-events: all;
        top: 24px;

        &:hover, &:focus {
            background: darken( $orange, 5% );
            opacity: 1;
            outline: none;
        }
    }

    @include media-breakpoint-down(xs) { 
        height: 44px;
        width: 44px;
        top: 30px;
    }

    .scrolled & {
        transition: $delayedTransition;
        opacity: 1;
        pointer-events: all;

        &:hover, &:focus {
            transition: $defaultTransition;
            background: darken( $orange, 5% );
            opacity: 1;
            outline: none;
        }
    }

    &:hover {
        transition: $defaultTransition;
    }

    .menuOpened & {
        background: transparent;
        box-shadow: none;
        &:focus { 
            background: transparent;
            box-shadow: none;
        }

    }
}
.hamburger-box {
    width: 26px;
    height: 13px;
    @include media-breakpoint-down(xs) { 
        width: 20px;
        height: 10px;
    }
}
.hamburger-inner {
    margin-top: -1px;
    background: $white;
    width: 26px;
    height: 2px;
    @include media-breakpoint-down(xs) { 
        margin-top: 0;
        width: 20px;
        top: -38%;
        left: -19%;
    }

    .hamburger.is-active & {
        background: $white;
    }

    &:after, &:before {
        background: $white;
        width: 26px;
        height: 2px;
        @include media-breakpoint-down(xs) { 
            width: 20px;
        }

        .hamburger.is-active & {
            background: $white;
        }
    }
    &:before {
        top: -5px;
        @include media-breakpoint-down(xs) { 
            top: -4px;
        }
    }
    &:after {
        bottom: -5px;
        @include media-breakpoint-down(xs) { 
            bottom: -4px;
        }
    }
}

// Logo
.brand {
    position: relative;
    z-index: 2;
    height: 50px;
    display: flex;
    max-width: 240px;
    height: auto;


    @include media-breakpoint-down(sm) {
        max-width: 220px;
    }
    @include media-breakpoint-down(xs) {
        max-width: 200px;
    }

    img {
        display: none;
        min-width: 200px;
    }

    .lightLogo {
        .lightHeader & {
            display: block;
        }
        .lightHeader.scrolled & {
            display: none;
        }
        .lightHeader.menuOpened &, .lightHeader.scrolled.menuOpened &,
        .darkHeader.menuOpened &, .darkHeader.scrolled.menuOpened & {
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
    }
    .darkLogo {
        .darkHeader & {
            display: block;
        }
        .lightHeader.scrolled & {
            display: block;
        }
        .lightHeader.menuOpened &, .lightHeader.scrolled.menuOpened &,
        .darkHeader.menuOpened &, .darkHeader.scrolled.menuOpened & {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}


// Primary navigation menu (regular version)
.primaryNavigation {
    @extend .ml-auto;
    transition: $delayedTransition;

    .scrolled & {
        opacity: 0;
        transition: $defaultTransition;
        pointer-events: none;
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }

    li {
        display: flex;
        align-items: center;

        &:last-child a {
            margin-right: 0;
        }
    }

    a {
        color: $darkerGray;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 1.2;
        padding: 4px 5px 2px 5px;
        border-bottom: 2px solid transparent;
        margin: 0 10px;
        font-weight: 700;
        text-decoration: none;
        display: block;

        .lightHeader & {
            color: $white;
        }
        .lightHeader.scrolled & {
            color: $darkerGray;
        }

        &:hover, &:focus {
            color: $orange;
        }
    }
    .active a[aria-current="page"] {
        border-bottom-color: $orange;
    }

    .archive.author & {
        .active a {
            border-bottom-color: transparent;
        }
        .menu-who-we-are a {
            border-bottom-color: $orange;
        }
    }
}


// Primary navigation menu (extended/mobile version)
.extendedPrimaryNavigation {
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: $darkGreen;
    color: $white;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    transition: $defaultTransition;
    transform: translateY(-100%);

    background-image: url(../images/orange_grid_vert.svg);
    background-repeat: no-repeat;
    background-position: -40px center;
    background-size: 80px 150px;


    @include media-breakpoint-down(sm) {
        width: 100%;
        left: 0;
    }

    .menuOpened & {
        transform: translateY(0);
    }

    .nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: get-maxwidth('xl') / 2;
        padding: 80px 10px 0 250px;


        @media screen and (max-height: 480px)  {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;

            li {
                width: 50%;
                padding-left: 30px;
            }
        }

        @include media-breakpoint-down(lg) {
            width: get-maxwidth('lg') / 2;
            padding: 90px 10px 0 120px;
        }
        @include media-breakpoint-down(md) {
            width: get-maxwidth('md') / 2;
            padding: 90px 10px 0 70px;
        }
        @include media-breakpoint-down(sm) {
            padding: 90px 10px 0 50px;
            width: 100%;
        }

        a {
            font-size: 34px;
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
            padding: 5px;
            border-bottom: 5px solid transparent;
            margin: 5px 0;
            line-height: 1;
            font-weight: 900;
            text-align: right;
            display: block;

            &:hover, &:focus {
                color: $orange;
            }

            @include media-breakpoint-down(lg) {
                font-size: 32px;
            }
            @include media-breakpoint-down(md) {
                font-size: 30px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 28px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 26px;
                margin: 4px 0;
                padding: 4px;
                border-bottom: 3px solid transparent;
            }

            @media screen and (max-height: 550px)  {
                font-size: 26px;
            }
            @media screen and (max-height: 480px)  {
                font-size: 24px;
                border-bottom-width: 2px;
                margin: 3px 0;
            }
        }
        .active a {
            border-bottom-color: $orange;
        }
    }

    .socialLinks {
        margin-top: 40px;
        padding: 0 10px 0 0;
        width: get-maxwidth('xl') / 2;
        text-align: right;

        @media screen and (max-height: 480px)  {
            margin-top: 20px;
        }

        &>div {
            display: flex;
            justify-content: flex-end;
        }

        @include media-breakpoint-down(lg) {
            width: get-maxwidth('lg') / 2;
        }
        @include media-breakpoint-down(md) {
            width: get-maxwidth('md') / 2;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        @include media-breakpoint-down(xs) {
            margin-top: 25px;
            padding: 0 8px 0 0;
        }


        a {
            @extend .btnAnimation; 
            
            height: 50px;
            width: 50px;
            background: $orange;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;

            &:hover, &:focus {
                background: darken($orange, 5%);
                opacity: 1;
                outline: none;
            }

            @include media-breakpoint-down(lg) {
                height: 46px;
                width: 46px;
            }
            @include media-breakpoint-down(md) {
                height: 42px;
                width: 42px;
            }
            @include media-breakpoint-down(sm) {
                height: 40px;
                width: 40px;
            }
            @include media-breakpoint-down(xs) {
                height: 38px;
                width: 38px;
            }

            img {
                max-height: 16px;
                width: auto;
                max-width: 20px;
                height: auto;  
                @include media-breakpoint-down(lg) {
                    max-height: 15px;
                    max-width: 18px;
                }
                @include media-breakpoint-down(md) {
                    max-height: 14px;
                    max-width: 16px;
                }
                @include media-breakpoint-down(sm) {
                    max-height: 13px;
                    max-width: 15px;
                }
            }


        }



        &:before {
            position: relative;
            content: '';
            display: inline-block;
            width: 52px;
            height: 2px;
            background: $orange;
            margin-bottom: 30px;

            @media screen and (max-height: 480px)  {
                margin-bottom: 15px;
            }
        }
    }
}


// Header CTA
.menuCtaContainer {
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $darkerGray;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    transition: $defaultTransition;
    transform: translateY(100%);

    background: $white url(../images/orange_grid_vert.svg) no-repeat calc(100% + 40px) center;
    background-size: 80px 150px;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    .menuOpened & {
        transform: translateY(0);
    }
}
.menuCta {
    width: get-maxwidth('xl') / 2;
    padding: 110px 160px 0 10px;


    @include media-breakpoint-down(lg) {
        width: get-maxwidth('lg') / 2;
        padding: 90px 100px 0 10px;
    }
    @include media-breakpoint-down(md) {
        width: get-maxwidth('md') / 2;
        padding: 90px 45px 0 10px;
    }

    .ctaText {
        h1, h2, h3, h4, h5, h6, p, pre, ul, ol, dl, blockquote {
            margin-bottom: .5rem;
        }
    }
}
