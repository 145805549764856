// Custom widgets styles
.widget {
    margin-bottom: 30px;
    margin-top: 30px;


    h3 {
        font-size: 31px;

        @include media-breakpoint-down(lg) { 
            font-size: 29px;
        }
        @include media-breakpoint-down(md) { 
            font-size: 28px;
        }
        @include media-breakpoint-down(sm) { 
            font-size: 27px;
        }
        @include media-breakpoint-down(xs) { 
            font-size: 26px;
        }

        &:after {
            content: '';
            display: block;
            width: 65px;
            height: 2px;
            background: $orange;
            margin: 20px 0 25px 0;
        }
    }

    .ptkoTextWidget_text {
        font-size: 22px;
        line-height: 1.27;
    }

    .serviceSidebar & {
        padding-left: 30px;
    }

    .socialSharingLinks {
        display: flex;

        a {
            cursor: pointer;
            width: 50px;
            height: 50px;
            margin-right: 5px;
            background-color: $lightGray;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: darken( $lightGray, 5% );
            }
            img {
                max-height: 16px;
                width: auto;
                max-width: 20px;
                height: auto;
            }
        }
    }
    &.ptko_socialsharing_widget h6 {
        font-size: 16px;
        font-weight: 700;

        &:after {
            content: '';
            display: block;
            width: 65px;
            height: 2px;
            background: $orange;
            margin: 30px 0;
        }
    }

    .sidebarPromo {
        position: relative;
        margin-bottom: 20px;


        &:hover {
            .zoomable img {
                transform: scale(1.2);
                opacity: .8;
            }

            .promoButton {
                &:before {
                    transform: translateX(0);
                    opacity: 1;
                }
                &:after {
                    background: transparent url(../images/orange_arrow_right.svg) no-repeat center;
                    background-size: 23px 18px;
                }
            }
        }

        .promoContent {
            padding: 20px;

            .ctaLabel {
                font-size: 14px;
            }

            h4 {
                font-size: 22px;
            }

            .learnMore {
                font-size: 16px;
            }
        }

        .zoomable {
            position: relative; 
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            overflow: hidden;

            img {
                position: relative; 
                width: 100%;
            }
        }

        .promoButton {
            position: absolute;
            bottom: 10px;
            right: -24px;
            transition: $defaultTransition;
            box-shadow: 4px 8px 12px 0 transparentize($black, .8) !important;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            padding: 13px 16px;
            outline: none;
            display: block;
            background: $orange;
            overflow: hidden;


            @include media-breakpoint-down(sm) {
                right: 20px; 
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -1px;
                left: -1px;
                height: calc(100% + 2px);
                width: calc(100% + 2px);
                border-radius: 50%;
                background: $white;
                opacity: 0;
                transform: translateX(-100%);
                transition: $defaultTransition;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: transparent url(../images/white_arrow_right.svg) no-repeat center;
                background-size: 23px 18px;
            }
        }

        &.caseStudy {
            .promoContent {
                background: $darkGreen;
                color: $white;

                h4 {
                    color: $white;
                }
            }
        }
    }
}
