.supsystic-tables-wrap {
  table {
    border: none !important;
    border-bottom: solid $tableOdd 3px !important;

    &.oneColumn {
      tr {
        border: none;
        border-left: solid thin $tableOdd;
        border-right: solid thin $tableOdd;

        &:first-child {
          border-top: solid thin $tableOdd;
        }

        &:last-child {
          border-bottom: solid thin $tableOdd;
        }
      }
    }

    thead {
      tr {
        background-color: map-get($theme-colors, "secondary");
        color: $white;
        border: none;

        :not(:last-child) {
          border-right: solid thin white;
        }
      }
    }

    tbody {
      tr {
        transition: background-color 300ms linear;
        color: black;

        td:first-child {
          font-weight: bold;
        }

        &:nth-child(odd) {
          background-color: $tableOdd;
        }

        &:nth-child(even) {
          background-color: $tableEven;
        }

        &:hover {
          background-color: map-get($theme-colors, "primary") !important;
          color: white;
        }
      }
    }

    &:not(.oneClolumn) {
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: $tableOdd;
          }

          &:nth-child(even) {
            background-color: $tableEven;
          }

          td {
            border: none !important;
          }
        }
      }
    }
  }
}

table {
  margin: 2rem 0 4rem 0;

  tr {
    &:first-child {
      background-color: #245e33 !important;
      color: white;
    }

    &:nth-child(odd) {
      background-color: $lighterGreen;
    }

    &:not(:first-child) {
      &>td:first-child {
        background-color: #e28d39 !important;
        color: white;
      }
    }

    td {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}