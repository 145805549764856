/**
 * Single Post
 */
.postHeader {
    padding-top: 218px;
    min-height: 690px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    margin-bottom: 60px;
    background-color: $lightGray;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(lg) { 
        padding-top: 108px;
    }

    @include media-breakpoint-down(sm) { 
        min-height: 460px;
    }

    .single-post & {
        background-color: $orange;
    }

    .single-case-study & {
        .promoContent {
            background: transparentize( $darkGreen, .1);

            .ctaLabel {
                &:after {
                    background: $orange;
                }
            }
        }
        
    }
    

    .promoContent {
        background: transparentize( $orange, .1);
        color: $white;
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 1;
        min-height: 345px;

        @include media-breakpoint-down(sm) { 
            width: 100%;
            min-height: 40%;
        }
        .ctaLabel {
            margin-bottom: 15px;

            &:after {
                background: $white;
            }
        }

        h1 {
            font-size: 42px;
            margin-bottom: 10px;
            line-height: 1.1;

            @include media-breakpoint-down(lg) { 
                font-size: 40px;
            }
            @include media-breakpoint-down(md) { 
                font-size: 38px;
            }
            @include media-breakpoint-down(sm) { 
                font-size: 34px;
            }
            @include media-breakpoint-down(xs) { 
                font-size: 30px;
            }
        }

        .promoText {
            width: get-maxwidth('xl') / 2;
            padding: 30px 20px 30px 10px;
            font-size: 16px;

            @include media-breakpoint-down(lg) {
                width: get-maxwidth('lg') / 2;
                padding: 30px 15px 30px 10px;
            }
            @include media-breakpoint-down(md) {
                width: get-maxwidth('md') / 2;
                padding: 30px 10px;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}

.postContent {
    padding-bottom: 60px;

    .postSharing {
        text-align: center;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }

        .widget {
            @include media-breakpoint-down(sm) {
                display: flex;
                margin-bottom: 20px;
                align-items: center;
            } 
        }

    }

    .read-more {
        margin-top: 2.5rem;
    }
}

.postBody {
    @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
    }
}

.shareLabel {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    border-bottom: 2px solid $orange;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        margin-right: 10px;
    }
}

.theAuthor {
    .ctaLabel {
        font-size: 14px;
        margin-bottom: 5px;
    }

    img {
        width: 100%;
    }
}



/**
 * Easy Footnotes plugin
 */
.easy-footnote {
    a {
        font-weight: 700;
        margin-right: 6px;
    }
}
.easy-footnotes-wrapper {
    margin-top: 60px;
    font-size: .8rem;
    color: $normalGray;
}
.easy-footnote-to-top {
    text-decoration: none;

    &:after {
        content: '\2191' !important;
    }
}


/**
 * Blog Index page
 */
section.section.filters {
    padding-bottom: 0;
    position: sticky;
    top: 114px;
    z-index: 999;
}
main.main {
    .postFilterContentTypes, .postFilterCategories, .postFilterAuthors {
        cursor: pointer;
        position: relative;
        padding: 20px 50px 20px 65px;
        box-shadow: 2px 3px 12px 0 transparentize($black, .8) !important;
        background: $white url(../images/filters.svg) no-repeat 20px center;
        background-size: 30px 29px;
        font-size: 16px;
        display: inline-block;
        z-index: 10;
        width: 33%;
        max-width: 100%;
        
        @include media-breakpoint-down(sm) {
            margin-top: 5px;
            display: block;
            width: 100%;

        }

        &:after {
            content: '\25bc';
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -6px;
            line-height: 1;
            font-size: 12px;
            //transition: $defaultTransition;
        }

        &.filtersOpen {
            //&:after {
               // transform: rotate(180deg);
            //}
        }
    }

    .postFilterContentTypes {
        z-index: 20;
    }

    .lowerFilter {
        z-index: 5;
    }

    .contentTypesFilterList, .categoriesFilterList, .authorsFilterList {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        box-shadow: 2px 3px 12px 0 transparentize($black, .8) !important;
        background: $white;

        li {
            padding: 0;
            line-height: 1.1;
            margin: 0;

            ul li a {
                padding-left: 50px;
            }


            &:before {
                display: none;
            }

            a {
                padding: 12px 30px;
                border-bottom: 1px solid $lighterGray;
                text-decoration: none;
                color: $darkerGray;
                display: block;            

                &:hover {
                    background: $lightGray;
                }
            }
        }
    }
}


.postList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &>article {
        margin-bottom: 60px;
        max-width: 100%;
        
        @include media-breakpoint-down(sm) {
            margin-bottom: 80px;
        }
        @include media-breakpoint-down(xs) {
            margin-bottom: 60px;
        }

        &>a {
            min-height: 400px;
            display: flex;
            background: $lightGray;
            box-shadow: 1px 4px 10px 0 transparentize($black, .8) !important;
            color: $darkerGray;
            text-decoration: none;
            flex-wrap: wrap;

            &:hover {
                background: darken( $lightGray, 3%); 
            }

            
            @include media-breakpoint-down(sm) {
                min-height: 0;
            }
        }

        &.has-post-thumbnail {
            &>a {
                min-height: 500px;

                @include media-breakpoint-down(md) {
                    min-height: 400px;
                }
                @include media-breakpoint-down(sm) {
                    min-height: 0;
                }
            }
        }
    }
}
.postListThumbnail {
    max-width: 50%;
    width: 720px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
        max-width: 45%;
    }
    @include media-breakpoint-down(md) {
        max-width: 40%;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        padding-bottom: 50%;
    }
    @include media-breakpoint-down(xs) {
        padding-bottom: 60%;
    }

    
}
.postListContent {
    max-width: 100%;
    width: 1120px;
    padding: 60px 200px 60px 150px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    position: relative;


    @include media-breakpoint-down(lg) {
        padding: 60px 100px 60px 250px;
    }
    @include media-breakpoint-down(md) {
        padding: 60px 50px 60px 250px;
    }
    @include media-breakpoint-down(sm) {
        padding: 80px 20px 60px 20px;
    }
    @include media-breakpoint-down(xs) {
        padding: 60px 20px 40px 20px;
    }

    h2 {
        margin-bottom: 10px;
    }

    .has-post-thumbnail & {
        max-width: 50%;
        width: 720px;
        padding: 60px 130px 60px 150px;

        @include media-breakpoint-down(lg) {
            padding: 60px 60px 60px 150px;
            max-width: 55%;
        }
        @include media-breakpoint-down(md) {
            padding: 60px 30px 60px 120px;
            max-width: 60%;
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
            width: 100%;
            padding: 80px 20px 60px 20px;
        }
        @include media-breakpoint-down(xs) {
            padding: 60px 20px 40px 20px;
        }
    }

    .headshot {
        position: absolute;
        left: -90px;
        top: 50%;
        margin-top: -90px;
        border-radius: 50%;
        height: 180px;
        width: 180px;

        @include media-breakpoint-down(lg) {
            left: 30px;            
        }
        @include media-breakpoint-down(sm) {
            top: -60px;
            left: 50%;
            margin-left: -60px;
            margin-top: 0;
            height: 120px;
            width: 120px;
        }
        @include media-breakpoint-down(xs) {
            top: -40px;
            margin-left: -40px;
            height: 80px;
            width: 80px;
        }

        .has-post-thumbnail & { 
            left: -90px;

            @include media-breakpoint-down(sm) {
                top: -65px;
                left: 50%;
                margin-left: -65px;
                margin-top: 0;
            }
            @include media-breakpoint-down(xs) {
                top: -40px;
                margin-left: -40px;
            }
        }

        a:hover & {
            opacity: 1;
        }
    }

    .readMore {
        color: $orange;

        &:after {
            a:hover & {
                margin-left: 12px;
            }
        }
    }

    .ctaLabel, .ctaTitle, .postMeta, p {
        width: 100%;
    }
}

.postMeta {
    font-size: 15px;
    margin-bottom: 10px;
}


/**
 * Single Case Study
 */

.single-case-study {
    .backLinkContainer {
        .container {
            position: relative;

            &:after {
                content: "";
                display: block;
                height: 2px;
                position: absolute;
                top: -60px;
                left: $grid-gutter-width / 2;
                right: $grid-gutter-width / 2;
                background: $lightGray;
            }
        }
    }
}

.prevNext {
    display: flex;
    justify-content: space-between;
   
    @include media-breakpoint-down(sm) { 
        flex-direction: column;
    }
}

.prevCS, .nextCS {
    display: flex;
    background: $lightGreen;
    width: calc(50% - 1px);
    font-size: 15px;
    line-height: 1.2;
    transition: $defaultTransition;

    @include media-breakpoint-down(sm) { 
        width: 100%;
    }

    &:hover {
        background: darken( $lightGreen, 5%);
    }

    .ctaLabel {
        &:after {
            display: block;
            margin: 25px 0;
            top: 0;
            background: $white;
        }
    }

    a {
        width: get-maxwidth('xl') / 2;
        padding: 65px;
        color: $white;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            width: get-maxwidth('lg') / 2;
            padding: 45px;
        }
        @include media-breakpoint-down(md) {
            width: get-maxwidth('md') / 2;
            padding: 30px;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        @include media-breakpoint-down(xs) {
            padding: 30px 15px;
        }
    }
}
.prevCS {
    justify-content: flex-end;
    @include media-breakpoint-down(sm) { 
        margin-bottom: 2px;
    }
}
.nextCS {
    justify-content: flex-start;
} 