.topFooter {
    background: $orange;
    color: $white;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 25px transparentize( $black, .5 );

    h1 {
        font-size: 48px;
        text-transform: none;
        @include media-breakpoint-down(lg) {
            font-size: 44px;
        }
        @include media-breakpoint-down(md) {
            font-size: 40px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 35px;
        }
        @include media-breakpoint-down(xs) {
            font-size: 30px;
        }
    }
}

.signupFormFooter {

    align-items: center;
        
    @include media-breakpoint-down(xs) { 
        padding-left: 0;
    }
}

.bottomFooter {
    color: $white;
    background: $darkGray;
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 16px;

    p, h1, h2, h3, h4, h5, h6 {
        margin-bottom: .5rem;
    }

    .ctaLabel {
        font-size: 48px;
        text-transform: none;
        @include media-breakpoint-down(lg) {
            font-size: 44px;
        }
        @include media-breakpoint-down(md) {
            font-size: 40px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 35px;
        }
        @include media-breakpoint-down(xs) {
            font-size: 30px;
        }

        &:after {
            display: block;
            margin: 20px 0;
        }
    }

    h6 {
        font-size: 19px;
        font-weight: 700;
        font-style: normal;
        
        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    .socialLinks {
        img {
            max-height: 16px;
            width: auto;
            max-width: 20px;
            height: auto;
            margin: 10px;
        }
        a:first-of-type {
            img {
                margin-left: 0;
            }
        }
    }
}
.copyrightNote {
    font-size: 12px;
    font-weight: 500;
    color: $white;
    background: $darkerGray;
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1;
    text-align: center;
    word-spacing: 2px;

    a {
        color: $white;
    }
}

.footerContent {
    * {
        color: $white;
    }

    .ctaLabel:after {
        background: $white;
    }
    
}

.contactFormFooter {
    top: 5.5em;
    @include media-breakpoint-down(md) {
        top: 1.5em !important;
    }
}