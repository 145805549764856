@use "sass:map";
// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
$grid-gutter-width: 20px !default;

$xxl: 1400px;

// Transitions
$defaultTransition: all 0.28s ease-in-out;
$delayedTransition: all 0.28s ease-in-out 0.34s;
$slowTransition: all 0.5s ease-in-out;

// Colors
$orange: #e28d39 !default;
$lighterGreen: #e3f2eb !default;
$lighterGreen2: #bddfcf !default;
$lightGreen: #73bd9a !default;
$darkGreen: #245e33 !default;
$lighterGray: #f5f5f5 !default;
$lightGray: #f1f8f5 !default;
$normalGray2: #888888 !default;
$normalGray: #4a4a4a !default;
$darkGray: #333333 !default;
$darkerGray: #222222 !default;
$tableOdd: #deede6 !default;
$tableEven: white !default;

$yellow: #ffc000 !default;
$red: #881100 !default;

$theme-colors: (
  primary: $orange,
  secondary: $darkGreen,
  success: $lightGreen,
  info: $lightGray,
  warning: $yellow,
  danger: $red,
  light: $lighterGray,
  dark: $normalGray2,
) !default;

// Overwriting Bootstrap default font families
$sansSerif: "Visby CF", sans-serif;
$font-family-base: $sansSerif !default;
$headings-font-family: $sansSerif !default;

// Form Fontsize Variable
$form-font-size: 0.95rem;
$form-line-height: 1.55rem;
